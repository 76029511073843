import { FC } from "react";
import OutlinedButton from "components/common/OutlinedButton";
import CareerPageLayout from "./components/CareerPageLayout";

const FinanceManager: FC = () => {
  return (
    <CareerPageLayout
      title="Finance Manager - Management Company"
      published="February 25th"
      description="The Finance Manager - Management Company will be instrumental in overseeing the financial functions of our Management Company while supporting compliance efforts."
      tags={["100% Remote", "Full-time"]}
    >
      <p>
        <b>Role Summary:</b>
        <br />
        The Finance Manager - Management Company will be instrumental in overseeing the financial functions of our
        Management Company while supporting compliance efforts. In this role, you will manage financial processes,
        enhance internal controls, and ensure regulatory compliance to uphold the firm's financial integrity. You will
        report directly to and collaborate closely with the Head of Finance and Compliance, Michael Cho.
      </p>

      <p>
        <b>Key Responsibilities:</b>
        <br />
        <ul>
          <li>
            Oversee the budgeting and forecasting process for the Management Company as part of annual planning and
            production of monthly budget updates.
          </li>
          <li>
            Oversee the day-to-day accounting operations, including invoices, accounts payable, accounts receivable, and
            general ledger maintenance.
          </li>
          <li>Reconcile financials by reviewing and validating reports to ensure accuracy with internal records.</li>
          <li>Assist with Management Company and General Partnership accounting and tax review.</li>
          <li>Create and manage finance programs and processes (e.g., Growth & Caregiving and TITANs).</li>
          <li>
            Manage compliance-related programs and procedures, including annual compliance training and quarterly
            compliance certifications.
          </li>
          <li>Assist with regulatory filings such as Forms ADV and PF, ensuring accuracy and timeliness.</li>
          <li>Support regulatory audits by providing required documentation and analysis.</li>
          <li>
            Review and process legal and governance documents, such as transfer agreements, GP agreements, employee
            carry plans, etc.
          </li>
          <li>
            Assist with onboarding, evaluation, and management of business partners and vendors related to finance &
            compliance.
          </li>
          <li>Assist with investor relations, ad-hoc investor requests, and fundraising data room compilation.</li>
          <li>Maintain and update databases (e.g., Cerebro) with accurate financial and compliance information.</li>
          <li>Oversee the finance functions for our 776 incubations.</li>
          <li>
            Execute ad-hoc projects to compile, clean, and manage data to assist with investor relations, fundraising,
            and incubations.
          </li>
          <li>Collaborate with internal teams to enhance financial operational systems and processes.</li>
        </ul>
      </p>

      <p>
        <b>Qualifications:</b>
        <ul>
          <li>5-8 years of finance and accounting experience</li>
          <li>Audit, Big 4, VC, Private Equity, or Hedge Funds experience preferred, but not required</li>
          <li>
            Proficiency in financial accounting with a strong understanding of financial concepts, including financial
            statement analysis, budgeting, forecasting, and financial modeling.
          </li>
          <li>Experience with accounting software and other financial systems</li>
          <li>Strong attention to detail and ability to multitask in a fast-paced environment</li>
          <li>You love being scrappy! There's no project too big or too small for you to sink your teeth into.</li>
          <li>Excellent communication and organizational skills.</li>
        </ul>
      </p>

      <p>
        <b>776 Table Stakes:</b>
        <ul>
          <li>
            <i>🏆</i> First off, you're here to win. We care about our team and we're deeply driven individuals. Do not
            apply for this job if you're not prepared to be challenged to be your best. We have high expectations of
            ourselves and of our team members. We commit to helping you achieve your best work in a supportive,
            growth-oriented environment. This works best if you show up genuinely eager for this journey together.
          </li>
          <li>
            <i>💻</i> This role, like all roles at the firm, will have a good deal of autonomy. We're a remote-first
            team and have designed our culture for a balance of synchronous and asynchronous work with people operating
            from all over the world at any given time. We expect you to be a responsible adult who is intrinsically
            driven and enjoys hitting (and exceeding) goals set for them. We commit to equipping you with the tools and
            resources you need to be successful along the way. It's then your job to make it so.
          </li>
          <li>
            <i>🤸</i> We want to proactively acknowledge that there will be last minute demands that will require your
            flexibility. We are, after all, still a very young company with some seriously big goals. We'll do our best
            to keep reasonable boundaries and, when those inevitable moments of unexpected asks arrive, we promise to
            express gratitude and find balance in other ways.
          </li>
          <li>
            <i>🌎</i> We are committed to hiring a diverse team and building an inclusive, equitable culture.
          </li>
        </ul>
      </p>

      <p>
        <b>👉 Please also:</b>
        <ul className="noliststlye">
          <li>
            <i>🧩</i> Have a genuine passion for problem-solving
          </li>
          <li>
            <i>🤝</i> Be service-orientated and compassionate
          </li>
          <li>
            <i>✍️</i> Possess excellent, inclusive communication skills
          </li>
          <li>
            <i>🙌</i> Be aware that humility and confidence do not have to be mutually exclusive
          </li>
          <li>
            <i>💬</i> Choose to bring your unique perspective to conversations
          </li>
          <li>
            <i>🌟</i> Know that your experience and worldview matter
          </li>
          <li>
            <i>⛔</i>Don't be an asshole
          </li>
        </ul>
      </p>

      <p>
        <b>Compensation & Benefits:</b>
        <br />
        💸 This role will receive an annual base salary of $185,500 with an annual bonus of up to 25% of the base
        salary, regardless of US location. The annual bonus is discretionary and based on firm performance, as well as
        individual performance.
        <br />
        <br />
        Below is a peek into some of our benefits:
        <ul className="noliststlye">
          <li>
            <i>⚕</i> 100% paid employee and dependent health care premiums
          </li>
          <li>
            <i>🏠</i> Work from anywhere in the United States
          </li>
          <li>
            <i>💕️</i> Generous & inclusive policies, including paid family leave
          </li>
          <li>
            <i>🕓</i> Flexible work hours and PTO
          </li>
          <li>
            <i>💪</i> Quarterly Wellness, Caregiving, and Workspace stipend
          </li>
          <li>
            <i>📚</i> Supportive culture designed to help you develop and grow
          </li>
          <li>
            <i>🎁</i> A host of unique perks and fringe benefits
          </li>
        </ul>
      </p>

      <p>
        <b>Other Things of Note:</b>
        <br />
        This role is remote and US based. Compensation at this stage in our firm's life cycle is not based on geographic
        location. This may change in the future.
        <br />
        <br />
        We encourage anyone to apply that may be interested, even if you feel you don't meet 100% of the qualifications
        above. We'd like to account for the fact that we may have overlooked, under-counted, or over-indexed on some of
        these requirements.
        <br />
        <br />
        That's it. We don't care where you went to college—or even if you did. It doesn't matter who you know. We care
        about what you can do to help us achieve outsized returns and maintain our values along the way.
      </p>

      <p>
        <b>About 776:</b>
        <br />
        Using technology to revolutionize venture capital, 776 aims to generate and distribute wealth that better serves
        the world in which we live. Anchored in decades of operational expertise in both product development and talent
        optimization, we stand at the crossroads of innovation and human potential. Partnering with startups at their
        earliest possible stage, we offer more than capital — we bring tried-and-true guidance, helping to scale
        businesses poised to redefine industries. At our core, we are a technology company that deploys venture capital.
        <br />
        <br />
        We are an early-stage, thesis agnostic, software-enabled VC firm that partners with exceptional founders. We
        believe people, culture, and community are core to producing outsized returns. Our portfolio companies are
        focused on building the businesses that will positively impact our world for generations to come. Founded by
        Alexis Ohanian in September 2020 alongside Founding Partner Katelin Holloway, the firm currently manages assets
        totaling more than $1B+ and is a fully remote US-based organization.
      </p>

      <p>
        <b>The Fine (But Important) Print:</b>
        <br />
        We are an equal opportunity employer that deeply values a diverse community — from our Limited Partners, to our
        firm, and our founder community. All applicants will be considered for employment without attention to race,
        color, religion, sex, sexual orientation, gender identity, national origin, veteran, ability status, family or
        parental status, or age.
        <br />
        776 prohibits discrimination and harassment of any kind. We are committed to providing employees with a work
        environment free of discrimination and harassment. We translate this policy to all aspects of employment
        including recruitment, hiring, promotions, performance management, terminations, wage and salary administration,
        benefits, and training.
      </p>

      <p>
        <b>Apply For This Role:</b>
        <br />
        To submit an application for the position, please complete form on link below:
      </p>

      <a
        href="https://776fund.typeform.com/to/iEmRyeHj?typeform-source=www.sevensevensix.com"
        target="_blank"
        rel="noreferrer"
      >
        <OutlinedButton>Apply now</OutlinedButton>
      </a>
    </CareerPageLayout>
  );
};

export default FinanceManager;
