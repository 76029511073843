import Careers from "pages/careers";
import Drops from "pages/Drops";
import FinanceManager from "pages/careers/FinanceManager";
import Home from "pages/Home";
import MusingsPage from "pages/Musings";
import News from "pages/News";
import NewsItem from "pages/NewsItem";
import Portfolio from "pages/Portfolio";
import Stats from "pages/Stats";
import Team from "pages/Team";
import LeadSoftwareDesignEngineer from "pages/careers/LeadSoftwareDesignEngineer";

export const RoutesEnum = {
  home: "/",
  news: "/news",
  careers: "/careers",
  financeManager: "/careers/finance-manager",
  careersHeadOfProduct: "/careers/head-of-product",
  leadSoftwareDesignEngineer: "/careers/lead-software-design-engineer",
  newsView: (id) => `/news/${id}`,
  team: "/team",
  teamView: (id) => `/team/${id}`,
  portfolio: "/portfolio",
  alexis: "/alexis",
};

export const routes = {
  home: {
    component: <Home />,
    path: "/",
  },
  news: {
    component: <News />,
    path: "/news",
  },
  newsView: {
    component: <NewsItem />,
    path: "/news/:id",
  },

  team: {
    component: <Team />,
    path: "/team/*",
  },

  portfolio: {
    component: <Portfolio />,
    path: "/portfolio",
  },

  careers: {
    component: <Careers />,
    path: "/careers",
  },

  drops: {
    component: <Drops />,
    path: "/drops",
  },

  financeManager: {
    component: <FinanceManager />,
    path: "/careers/finance-manager",
  },

  leadSoftwareDesignEngineer: {
    component: <LeadSoftwareDesignEngineer />,
    path: "/careers/lead-software-design-engineer",
  },

  alexis: {
    component: <Stats />,
    path: "/alexis",
  },

  musings: {
    component: <MusingsPage />,
    path: "/musings/:id",
  },
};
