import { FC } from "react";
import OutlinedButton from "components/common/OutlinedButton";
import CareerPageLayout from "./components/CareerPageLayout";

const LeadSoftwareDesignEngineer: FC = () => {
  return (
    <CareerPageLayout
      title="Lead Software/Design Engineer"
      published="March 6th"
      description=" The Lead Software / Design Engineer will be our first in-house technical hire, and will own all technical
          aspects of our proprietary software platform, Cerebro, that powers the investing, portfolio management,
          marketing processes, and general firm operations for our founders, team and investors."
      tags={["100% Remote", "Full-time"]}
    >
      <p>
        <b>Role Summary:</b>
        <br />
        The Lead Software / Design Engineer will be our first in-house technical hire, and will own all technical
        aspects of our proprietary software platform, Cerebro, that powers the investing, portfolio management,
        marketing processes, and general firm operations for our founders, team and investors. In addition to being an
        experienced, full-stack web application generalist, our ideal candidate has a sharp product and design sense and
        can craft user experiences to a high degree of visual and UX polish.
      </p>

      <p>
        <b>Key Responsibilities:</b>
        <br />
        <ul>
          <li>
            Work closely with the Head of Product to build and scale Cerebro, ensuring it meets the high standards and
            evolving needs of the business.
          </li>
          <li>
            Own technical execution from start to finish, driving the implementation of new features, bug fixes, and
            performance enhancements for Cerebro's web and mobile (iOS) applications.
          </li>
          <li>
            Make key decisions about the tech stack, infrastructure, and development practices to support Cerebro's
            evolution and the long term success of the platform.
          </li>
          <li>
            Participate in the UX design of Cerebro and bring a keen design eye to create intuitive, delightful, and
            user-centered experiences.
          </li>
          <li>Own and optimize databases (PostgreSQL) that handle critical business data.</li>
          <li>Manage 3rd party software integrations.</li>
          <li>Partner with the Head of Product to lead a remote (offshore) development team.</li>
          <li>
            Collaborate with executives and cross-functional stakeholders to drive innovation and efficiency. You'll be
            working closely with General Partner and Founder Alexis Ohanian who sets the vision for Cerebro and got us
            this far, to bring his ideas to life.
          </li>
          <i>
            <li>
              Please note: This is not an investment-track position. We want to find someone that is passionate about
              building exceptional user experiences and sees this as a unique opportunity to build a bespoke product
              with a huge impact for its users.{" "}
            </li>
          </i>
        </ul>
      </p>

      <p>
        <b>Qualifications:</b>
        <ul>
          <li>5+ years of full-stack development experience, ideally within a startup environment or as a founder.</li>
          <li>
            Experience with front-end and back-end technologies with a strong portfolio of projects that demonstrate
            technical depth and versatility. Experience with iOS development is preferred.
          </li>
          <li>
            You care deeply about building world-class user experiences. You have a demonstrated track record of
            building user-centric features and have a strong eye for design and user experience.
          </li>
          <li>
            You’re a self-starter capable of independently owning projects, balancing multiple priorities, and you are
            comfortable taking an ambiguous problem and breaking it into smaller parts with clear iterations.
          </li>
          <li>
            Passionate about technology and new tools, especially AI technologies, with demonstrated experience using
            and incorporating them.
          </li>
          <li>You love being scrappy! There's no project too big or too small for you to sink your teeth into.</li>
          <li>
            Experience on remote teams and working with cross-cultural partners is nice to have, but not required.
          </li>
          <li>Excellent communication and organizational skills.</li>
        </ul>
      </p>

      <p>
        <b>776 Table Stakes:</b>
        <ul>
          <li>
            <i>🏆</i> First off, you're here to win. We care about our team and we're deeply driven individuals. Do not
            apply for this job if you're not prepared to be challenged to be your best. We have high expectations of
            ourselves and of our team members. We commit to helping you achieve your best work in a supportive,
            growth-oriented environment. This works best if you show up genuinely eager for this journey together.
          </li>
          <li>
            <i>💻</i> This role, like all roles at the firm, will have a good deal of autonomy. We're a remote-first
            team and have designed our culture for a balance of synchronous and asynchronous work with people operating
            from all over the world at any given time. We expect you to be a responsible adult who is intrinsically
            driven and enjoys hitting (and exceeding) goals set for them. We commit to equipping you with the tools and
            resources you need to be successful along the way. It's then your job to make it so.
          </li>
          <li>
            <i>🤸</i> We want to proactively acknowledge that there will be last minute demands that will require your
            flexibility. We are, after all, still a very young company with some seriously big goals. We'll do our best
            to keep reasonable boundaries and, when those inevitable moments of unexpected asks arrive, we promise to
            express gratitude and find balance in other ways.
          </li>
          <li>
            <i>🌎</i> We are committed to hiring a diverse team and building an inclusive, equitable culture.
          </li>
        </ul>
      </p>

      <p>
        <b>👉 Please also:</b>
        <ul className="noliststlye">
          <li>
            <i>🧩</i> Have a genuine passion for problem-solving
          </li>
          <li>
            <i>🤝</i> Be service-orientated and compassionate
          </li>
          <li>
            <i>✍️</i> Possess excellent, inclusive communication skills
          </li>
          <li>
            <i>🙌</i> Be aware that humility and confidence do not have to be mutually exclusive
          </li>
          <li>
            <i>💬</i> Choose to bring your unique perspective to conversations
          </li>
          <li>
            <i>🌟</i> Know that your experience and worldview matter
          </li>
          <li>
            <i>⛔</i>Don't be an asshole
          </li>
        </ul>
      </p>

      <p>
        <b>Compensation & Benefits:</b>
        <br />
        The compensation for this role will be commensurate with years of experience. Regardless, this role will receive
        an annual salary with an annual bonus. The annual bonus is discretionary and based on firm performance, as well
        as individual performance.
        <br />
        <br />
        Below are some benefits to get you even more excited about this opportunity:
        <ul className="noliststlye">
          <li>
            <i>⚕</i> 100% paid employee and dependent health care premiums
          </li>
          <li>
            <i>🏠</i> Work from anywhere in the United States
          </li>
          <li>
            <i>💕️</i> Generous & inclusive policies, including paid family leave
          </li>
          <li>
            <i>🕓</i> Flexible work hours and PTO
          </li>
          <li>
            <i>💪</i> Quarterly Wellness, Caregiving, and Workspace stipend
          </li>
          <li>
            <i>📚</i> Supportive culture designed to help you develop and grow
          </li>
          <li>
            <i>🎁</i> A host of unique perks and fringe benefits
          </li>
        </ul>
      </p>

      <p>
        <b>Other Things of Note:</b>
        <br />
        We welcome applicants with a wide range of experience, starting with just a few years up to people with decades
        worth of experience.
        <br />
        <br />
        This role is remote and US based. Compensation at this stage in our firm's life cycle is not based on geographic
        location. This may change in the future.
        <br />
        <br />
        We encourage anyone to apply that may be interested, even if you feel you don't meet 100% of the qualifications
        above. We'd like to account for the fact that we may have overlooked, under-counted, or over-indexed on some of
        these requirements.
        <br />
        <br />
        That's it. We don't care where you went to college—or even if you did. It doesn't matter who you know. We care
        about what you can do to help us achieve outsized returns and maintain our values along the way.
      </p>

      <p>
        <b>About 776:</b>
        <br />
        Using technology to revolutionize venture capital, 776 aims to generate and distribute wealth that better serves
        the world in which we live. Anchored in decades of operational expertise in both product development and talent
        optimization, we stand at the crossroads of innovation and human potential. Partnering with startups at their
        earliest possible stage, we offer more than capital — we bring tried-and-true guidance, helping to scale
        businesses poised to redefine industries. At our core, we are a technology company that deploys venture capital.
        <br />
        <br />
        We are an early-stage, thesis agnostic, software-enabled VC firm that partners with exceptional founders. We
        believe people, culture, and community are core to producing outsized returns. Our portfolio companies are
        focused on building the businesses that will positively impact our world for generations to come. Founded by
        Alexis Ohanian in September 2020 alongside Founding Partner Katelin Holloway, the firm currently manages assets
        totaling more than $1B+ and is a fully remote US-based organization.
      </p>

      <p>
        <b>The Fine (But Important) Print:</b>
        <br />
        We are an equal opportunity employer that deeply values a diverse community — from our Limited Partners, to our
        firm, and our founder community. All applicants will be considered for employment without attention to race,
        color, religion, sex, sexual orientation, gender identity, national origin, veteran, ability status, family or
        parental status, or age.
        <br />
        776 prohibits discrimination and harassment of any kind. We are committed to providing employees with a work
        environment free of discrimination and harassment. We translate this policy to all aspects of employment
        including recruitment, hiring, promotions, performance management, terminations, wage and salary administration,
        benefits, and training.
      </p>

      <p>
        <b>Apply For This Role:</b>
        <br />
        To submit an application for the position, please complete form on link below:
      </p>

      <a
        href="https://776fund.typeform.com/to/dcH7WgFQ?typeform-source=www.sevensevensix.com"
        target="_blank"
        rel="noreferrer"
      >
        <OutlinedButton>Apply now</OutlinedButton>
      </a>
    </CareerPageLayout>
  );
};

export default LeadSoftwareDesignEngineer;
