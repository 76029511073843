import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";

import CapSticker from "assets/images/drops/cap-sticker.png";
import Cap from "assets/images/drops/cap.png";
import SweatherSticker from "assets/images/drops/sweater-sticker.png";
import Sweather from "assets/images/drops/sweater.png";
import TrousersSticker from "assets/images/drops/trousers-sticker.png";
import Trousers from "assets/images/drops/trousers.png";

import MadHappyJacketSticker from "assets/images/drops/776-Spring-2024-madhappy-jacket-sticker.png";
import MadHappyJacket from "assets/images/drops/776-Spring-2024-madhappy-jacket.png";
import MadHappySticker from "assets/images/drops/776-Spring-2024-madhappy-sticker.png";
import MadHappy from "assets/images/drops/776-Spring-2024-madhappy.png";

import OpalSticker from "assets/images/drops/776-Winter-2024-opal-sticker.png";
import Opal from "assets/images/drops/776-Winter-2024-opal.png";
import OpalBoxSticker from "assets/images/drops/776-Winter-2024-opal-box-sticker.png";
import OpalBox from "assets/images/drops/776-Winter-2024-opal-box.png";
import OpalOpenSticker from "assets/images/drops/776-Winter-2024-opal-box-open-sticker.png";
import OpalOpen from "assets/images/drops/776-Winter-2024-opal-open-box.png";

import Gallery from "components/gallery/Gallery";
import { createPortal } from "react-dom";

const CareersContainer = styled.section`
  max-width: 1740px;
  position: relative;
  margin: 0 auto;
  padding: 175px 70px 261px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;

  .content-wrapper {
    max-width: 1740px;
    width: 100%;
    position: relative;
    z-index: 1;
  }

  .slide-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    gap: 5px;

    overflow-y: hidden;
    overflow-x: auto;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;

    .item {
      width: 390px;
      height: 292px;
      flex-shrink: 0;

      user-select: none;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        pointer-events: none;
        user-select: none;
      }
      .image {
        display: block;
      }
      /* .hover {
        display: none;
      } */
      &:hover {
        cursor: pointer;
        transform: rotate(-4deg);
        /* .hover {
          display: block;
        } */
        /* .image {
          display: none;
        } */
      }
    }

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .drop-wrapper {
    .drop-info {
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .subtitle {
        font-size: 17px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.textSecondary};
      }

      h2 {
        width: 1100px;
        max-width: 100%;
        color: ${({ theme }) => theme.colors.textPrimary};
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin: 50px 0;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 25px 75px 25px;
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletPortrait} {
    .subtitle {
      font-size: 13px;
      line-height: 1;
    }

    h2 {
      font-size: 21px;
      line-height: 1;
    }

    .slide-wrapper {
      .item {
        width: 289px;
        height: 209px;
      }
    }
  }
`;

type Drop = {
  id: number;
  title: string;
  subtitle: string;
  imagesSticker: string[];
  images: string[];
};

const drops: Drop[] = [
  {
    id: 3,
    title: "DROP 002 // madhappy",
    subtitle: "Spring 2024",
    imagesSticker: [MadHappyJacketSticker, MadHappySticker],
    images: [MadHappyJacket, MadHappy],
  },
  {
    id: 2,
    title: "DROP 001 // opal",
    subtitle: "Winter 2024",
    imagesSticker: [OpalBoxSticker, OpalOpenSticker, OpalSticker],
    images: [OpalBox, OpalOpen, Opal],
  },
  {
    id: 1,
    title: "DROP 000 // madhappy",
    subtitle: "Winter 2023",
    imagesSticker: [CapSticker, SweatherSticker, TrousersSticker],
    images: [Cap, Sweather, Trousers],
  },
];

const Drops: FC = () => {
  const [indeces, setIndeces] = useState<{ dropIndex: number; imageIndex: number } | null>(null);

  const sliderRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  const openGallery = (dropIndex: number, imageIndex: number) => {
    setIndeces({ dropIndex, imageIndex });
  };

  return (
    <CareersContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} — Drops</title>
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        {drops.map((drop, dropIndex) => (
          <>
            <div className="drop-wrapper">
              <div className="drop-info">
                <span className="subtitle">{drop.subtitle}</span>
                <h2>{drop.title}</h2>
              </div>

              <div className="slide-wrapper" ref={sliderRef}>
                {drop.imagesSticker.map((item, index) => (
                  <div key={item} className="item" onClick={() => openGallery(dropIndex, index)}>
                    <img src={item} className="image" alt="drop" />
                    {/* <img src={drop.imagesHover[index]} className="hover" alt="drop image hovered" /> */}
                  </div>
                ))}
              </div>
            </div>

            {dropIndex !== drops.length - 1 && <div className="divider" />}
          </>
        ))}
      </div>

      {indeces !== null &&
        createPortal(
          <Gallery
            images={drops[indeces.dropIndex].images}
            index={indeces.imageIndex}
            onClose={() => setIndeces(null)}
          />,
          document.body
        )}
    </CareersContainer>
  );
};

export default Drops;
