import { FC } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { APP_TITLE } from "config/Config";
import useScrollToTop from "components/common/useScrollToTop";
import { Header } from "components";
import Shape from "components/common/Shape";
import Tag from "components/common/Tag";

const CareerPageLayoutWrapper = styled.section`
  max-width: 1740px;
  position: relative;
  margin: 0 auto;
  padding: 175px 70px 90px 70px;
  background-color: ${({ theme }) => theme.colors.layoutNeutral};
  display: flex;
  flex-direction: column;

  .content-wrapper {
    width: 1100px;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }

  .datetime {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }

  .title {
    width: 1100px;
    max-width: 100%;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }

  .text {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 40px;
    line-height: 24px;
    b {
      color: #000;
      margin-bottom: 5px;
    }
    p {
      margin-bottom: 30px;
    }

    ul {
      padding-left: 30px;
      margin: 0;
      li {
        line-height: 24px;
        margin: 5px 0;
        i {
          display: inline-block;
          font-style: normal;
          margin-right: 8px;
        }
      }
      &.noliststlye {
        list-style: none;
      }
    }
  }

  @media ${({ theme }) => theme.mediaQueries.bellowTabletLandscape} {
    padding: 115px 25px 75px 25px;
  }
`;

interface Props {
  title: string;
  published: string;
  description: string;
  tags: string[];
  children: React.ReactNode;
}

const CareerPageLayout: FC<Props> = ({ title, published, description, tags, children }) => {
  useScrollToTop();

  return (
    <CareerPageLayoutWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} - {APP_TITLE}
        </title>
        <meta name="description" content={description} />
      </Helmet>

      <Shape />

      <div className="content-wrapper">
        <Header showShape />

        <span className="datetime">Published: {published}</span>
        <h2 className="title">{title}</h2>

        <div className="tags">
          {tags.map((tag, index) => (
            <Tag key={index + "-" + tag}>{tag}</Tag>
          ))}
        </div>

        <div className="text">{children}</div>
      </div>
    </CareerPageLayoutWrapper>
  );
};

export default CareerPageLayout;
